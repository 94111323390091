import { Link, graphql } from 'gatsby'
import * as style from "../components/start/start.module.scss"
import Img from "gatsby-image"
import React from "react"
import ReactPlayer from 'react-player'
import Seo from "../components/seo"


const IndexPage = ({data}) => (
<>

    <Seo title="Home" bodyClass='home'/>

    <div className={style.start}>

<div className={style.start__artist}>
  <Link to="/st">
    The Artist
    </Link>
    <Img className={style.start__background} fluid={data.ArtistImage.childImageSharp.fluid}/>
</div>

<div className={style.start__choose}>
  Choose your site
  </div>

<div className={style.start__studio}>
  <Link to="/stp">
    The Studio
    </Link>
    <Img className={style.start__background} fluid={data.StudioImage.childImageSharp.fluid}/>
</div>

</div>



    

    <ReactPlayer playing={true} url={['https://specyalt.s3.us-east-2.amazonaws.com/welcome.mp3' ]} />

</>


)

export default IndexPage

export const query = graphql`
  query {
    ArtistImage: file(relativePath: {eq: "bg-landing_artist.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    StudioImage: file(relativePath: {eq: "bg-landing_studio.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`